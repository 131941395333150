<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="teros-elevation" style="width: 100%">
          <v-window v-model="currentView">
            <v-window-item>
              <v-card-actions
                class="d-block"
                style="
                  position: sticky;
                  top: -20px;
                  background-color: white;
                  z-index: 1;
                "
              >
                <v-item-group v-model="currentStep" class="text-center" mandatory>
                  <v-item v-slot="{ active }">
                    <v-btn
                      dense
                      text
                      tile
                      :style="{ opacity: active ? 1 : 0.8 }"
                      @click="currentStep = 0"
                      width="25%"
                    >
                      1 - Escopo
                      <v-icon
                        v-if="!canEdit"
                        class="ml-3"
                        small
                        color="#999"
                      >
                        mdi-lock
                      </v-icon>
                    </v-btn>
                  </v-item>
                  <v-item v-slot="{ active }">
                    <v-btn
                      dense
                      text
                      tile
                      :style="{ opacity: active ? 1 : 0.8 }"
                      @click="currentStep = 1"
                      width="25%"
                    >
                      2 - Tipificação
                      <v-icon
                        v-if="!canEdit"
                        class="ml-3"
                        small
                        color="#999"
                      >
                        mdi-lock
                      </v-icon>
                    </v-btn>
                  </v-item>
                  <v-item v-slot="{ active }">
                    <v-btn
                      dense
                      text
                      tile
                      :style="{ opacity: active ? 1 : 0.8 }"
                      @click="currentStep = 2"
                      width="25%"
                    >
                      3 - Detalhamento
                      <v-icon
                        v-if="!canEdit"
                        class="ml-3"
                        small
                        color="#999"
                      >
                        mdi-lock
                      </v-icon>
                    </v-btn>
                  </v-item>
                  <v-item v-slot="{ active }">
                    <v-btn
                      dense
                      text
                      tile
                      :style="{ opacity: active ? 1 : 0.8 }"
                      @click="currentStep = 3"
                      width="25%"
                    >
                      4 - Resultados
                      <v-icon
                        v-if="!canEdit"
                        class="ml-3"
                        small
                        color="#999"
                      >
                        mdi-lock
                      </v-icon>
                    </v-btn>
                  </v-item>
                </v-item-group>
                <v-progress-linear
                  rounded
                  color="success"
                  :value="((currentStep + 1) / 4) * 100"
                ></v-progress-linear>
              </v-card-actions>

              <v-card-text class="px-5 v-form-cadastro">
                <v-window v-model="currentStep">
                  <!-- ESCOPO -->
                  <v-window-item class="pt-2">
                    <v-row v-if="!isClient">
                      <v-col :cols="isClient ? 12 : 6" lg="8" sm="12" xs="12">
                        <kore-select
                          v-model="projeto.empresaId"
                          :valid.sync="fields.cliente.valid"
                          :label="fields.cliente"
                          :editable="false"
                          :opts="opts"
                        ></kore-select>
                      </v-col>
                      <v-col v-if="!isClient" cols="4" lg="4" sm="12" xs="12">
                        <kore-select
                          v-model="projeto.trabalho"
                          :valid.sync="fields.trabalho.valid"
                          :label="fields.trabalho"
                          :editable="canEdit && cliente.trabalho == 3"
                          :opts="opts"
                          @mutate="autoSave"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="3" sm="12" xs="12">
                        <kore-select
                          v-model="projeto.id_projeto"
                          :valid.sync="fields.id_projeto.valid"
                          :label="fields.id_projeto"
                          :editable="canEdit"
                          @mutate="autoSave"
                        ></kore-select>
                      </v-col>
                      <v-col lg="9" sm="12" xs="12">
                        <kore-select
                          v-model="projeto.titulo"
                          :valid.sync="fields.titulo.valid"
                          :label="fields.titulo"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row class="align-center">
                      <v-col lg="6" sm="12" xs="12">
                        <kore-select
                          v-model="projeto.status"                          
                          :label="fields.status"
                          :editable="canEditStatus"
                          :opts="opts"
                          @mutate="autoSave"
                        ></kore-select>
                      </v-col>
                      <v-col lg="6" sm="12" xs="12">
                        <kore-select
                          v-model="projeto.responsavelTxt"
                          :valid.sync="fields.responsavelTxt.valid"
                          :label="fields.responsavelTxt"
                          :editable="canEdit"
                          :opts="opts"
                          @mutate="autoSave"
                        ></kore-select>
                      </v-col>
                      <v-col lg="4" sm="12" xs="12">
                        <kore-select
                          v-model="projeto.data_inicio"
                          :valid.sync="fields.data_inicio.valid"
                          :label="fields.data_inicio"
                          :editable="canEdit"
                          @mutate="autoSave"
                        ></kore-select>
                      </v-col>
                      <v-col lg="4" sm="12" xs="12">
                        <kore-select
                          v-model="projeto.data_fim"
                          :valid.sync="fields.data_fim.valid"
                          :label="fields.data_fim"
                          :editable="canEdit"
                          @mutate="autoSave"
                        ></kore-select>
                      </v-col>
                      <v-col
                        v-if="isGalaposUser"
                        lg="4"
                        sm="12"
                        xs="12"
                        class="d-flex justify-center align-center"                        
                      >
                        <v-btn
                          depressed
                          color="secondary"
                          class="px-5 mb-sm-5 mb-lg-0 w-100"
                          @click="
                            onPlurianualOpen();
                            plurianualDialog = true;
                          "
                        >
                          Gerar outro ano base
                        </v-btn>

                        <form-modal
                          :cols="plurianualField"
                          :maxWidth="plurianualDialogWidth"
                          :opened.sync="plurianualDialog"
                          :opts="opts"
                          title="Gerar Outro Ano Base"
                          :value.sync="plurianualData"
                          @save="gerarProximoAnoBase($event)"
                        ></form-modal>
                      </v-col>
                    </v-row>
                    <!-- TODO descomentar quando precisar do CPF, E-mail e Telefone do responsável -->
                    <!-- <v-row>
                      <v-col class="v-label-input font-weight-medium pl-5">
                        Responsável pelo {{ name }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-sheet outlined rounded class="px-2 mt-2 py-2">
                          <v-row>
                            <v-col cols="12">
                              <kore-select
                                v-model="projeto.responsavelTxt"
                                :valid.sync="fields.responsavelTxt.valid"
                                :label="fields.responsavelTxt"
                                :editable="canEdit"
                                :opts="opts"
                                @mutate="autoSave"
                              ></kore-select>
                            </v-col>
                            <v-col cols="4">
                              <kore-select
                                v-model="projeto.responsavelId"
                                :label="fields.responsavelId_cpf"
                                :editable="false"
                                :opts="opts"
                              ></kore-select>
                            </v-col>
                            <v-col cols="4">
                              <kore-select
                                v-model="projeto.responsavelId"
                                :label="fields.responsavelId_email"
                                :editable="false"
                                :opts="opts"
                              ></kore-select>
                            </v-col>
                            <v-col cols="4">
                              <kore-select
                                v-model="projeto.responsavelId"
                                :label="fields.responsavelId_telefone"
                                :editable="false"
                                :opts="opts"
                              ></kore-select>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </v-col>
                    </v-row> -->
                  </v-window-item>

                  <!-- TIPIFICAÇÃO -->
                  <v-window-item>
                    <v-row v-if="isLeiDeInformatica">
                      <v-col>
                        <kore-select
                          @changeCheck="autoSave()"
                          @mutate="
                            projeto.ck_tipo_projetoId = 0;
                            autoSave();
                          "
                          v-model="projeto.tipo_projetoId"
                          :label="fields.tipo_projetoId"
                          :valid.sync="fields.tipo_projetoId.valid"
                          :editable="canEdit"
                          :opts="opts"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDeInformatica">
                      <v-col>
                        <kore-select
                          @changeCheck="autoSave()"
                          @mutate="
                            projeto.ck_troca_ppb = 0;
                            autoSave();
                          "
                          v-model="projeto.troca_ppb"
                          :label="fields.troca_ppb"
                          :valid.sync="fields.troca_ppb.valid"
                          :editable="canEdit && !isClient"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDeInformatica">
                      <v-col>
                        <kore-select
                          @changeCheck="autoSave()"
                          @mutate="
                            projeto.ck_alcance = 0;
                            autoSave();
                          "
                          v-model="alcance"
                          :label="fields.alcance"
                          :valid.sync="fields.alcance.valid"
                          :editable="canEdit"
                          :opts="opts"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <kore-select
                          @changeCheck="autoSave()"
                          @mutate="
                            projeto.ck_inovacao = 0;
                            autoSave();
                          "
                          v-model="inovacao"
                          :label="fields.inovacao"
                          :valid.sync="fields.inovacao.valid"
                          :editable="canEdit"
                          :opts="opts"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDeInformatica">
                      <v-col cols="4">
                        <kore-select
                          @changeCheck="autoSave()"
                          @mutate="
                            projeto.ck_qtde_publicacoes = 0;
                            autoSave();
                          "
                          v-model="projeto.qtde_publicacoes"
                          :valid.sync="fields.qtde_publicacoes.valid"
                          :label="fields.qtde_publicacoes"
                          :editable="canEdit"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDeInformatica">
                      <v-col>
                        <kore-select
                          @changeCheck="autoSave()"
                          @mutate="
                            projeto.ck_decreto = 0;
                            autoSave();
                          "
                          v-model="decreto"
                          :label="fields.decreto"
                          :valid.sync="fields.decreto.valid"
                          :editable="canEdit && !isClient"
                          :opts="opts"
                        ></kore-select>
                      </v-col>
                    </v-row>
                  </v-window-item>

                  <!-- DETALHAMENTO -->
                  <v-window-item>
                    <v-row>
                      <v-col>
                        <kore-select
                          v-model="projeto.descricao"
                          :label="fields.descricao"
                          :valid.sync="fields.descricao.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDoBem">
                      <v-col>
                        <kore-select
                          @changeCheck="autoSave()"
                          @mutate="
                            projeto.ck_tipo_pesquisaId = 0;
                            autoSave();
                          "
                          v-model="projeto.tipo_pesquisaId"
                          :label="fields.tipo_pesquisaId"
                          :valid.sync="fields.tipo_pesquisaId.valid"
                          :editable="canEdit"
                          :opts="opts"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDoBem">
                      <v-col>
                        <kore-select
                          @mutate="autoSave()"
                          v-model="projeto.area_projeto"
                          :label="fields.area_projeto"
                          :valid.sync="fields.area_projeto.valid"
                          :editable="canEdit"
                          :opts="opts"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDoBem">
                      <v-col>
                        <kore-select
                          v-model="projeto.keywords"
                          :label="fields.keywords"
                          :valid.sync="fields.keywords.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressKeywordsDebounce"
                          @paste="onPasteKeywordsDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDoBem">
                      <v-col>
                        <kore-select
                          @changeCheck="autoSave()"
                          @mutate="
                            projeto.ck_naturezaId = 0;
                            autoSave();
                          "
                          v-model="projeto.naturezaId"
                          :label="fields.naturezaId"
                          :valid.sync="fields.naturezaId.valid"
                          :editable="canEdit"
                          :opts="opts"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <kore-select
                          v-model="projeto.elemento_tecnologico"
                          :label="fields.elemento_tecnologico"
                          :valid.sync="fields.elemento_tecnologico.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <kore-select
                          v-model="projeto.desafio_tecnologico"
                          :label="fields.desafio_tecnologico"
                          :valid.sync="fields.desafio_tecnologico.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <kore-select
                          v-model="projeto.metodologia"
                          :label="fields.metodologia"
                          :valid.sync="fields.metodologia.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDoBem">
                      <v-col cols="12">
                        <kore-select
                          @changeCheck="autoSave()"
                          @mutate="autoSave()"
                          v-model="projeto.atividade_continua"
                          :label="fields.atividade_continua"
                          :valid.sync="fields.atividade_continua.valid"
                          :editable="canEdit"
                        ></kore-select>
                      </v-col>
                      <v-col cols="12" v-if="projeto.atividade_continua === 'Sim'">
                        <kore-select
                          v-model="projeto.atividadeAnoBase"
                          :label="fields.atividadeAnoBase"
                          :valid.sync="fields.atividadeAnoBase.valid"
                          :editable="canEdit"
                          @mutate="autoSave()"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="isLeiDoBem">
                      <v-col>
                        <kore-select
                          v-model="projeto.info_complementar"
                          :label="fields.info_complementar"
                          :valid.sync="fields.info_complementar.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                  </v-window-item>

                  <!-- RESULTADO -->
                  <v-window-item>
                    <v-row>
                      <v-col>
                        <kore-select
                          v-model="projeto.resultado_economico"
                          :label="fields.resultado_economico"
                          :valid.sync="fields.resultado_economico.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <kore-select
                          v-model="projeto.resultado_inovacao"
                          :label="fields.resultado_inovacao"
                          :valid.sync="fields.resultado_inovacao.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <kore-select
                          v-model="projeto.recursos_humanos"
                          :label="fields.recursos_humanos"
                          :valid.sync="fields.recursos_humanos.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <kore-select
                          v-model="projeto.vinculacao_materiais"
                          :label="fields.vinculacao_materiais"
                          :valid.sync="fields.vinculacao_materiais.valid"
                          :editable="canEdit"
                          @mutate="autoSave"
                          @keypress="onKeypressDebounce"
                          @paste="onPasteDebounce"
                        ></kore-select>
                      </v-col>
                    </v-row>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-window-item>

            <v-window-item>
              <master-detail
                :canDelete="false"
                :canEdit="false"
                :cols="cols"
                :contextOptions="contextOptions"
                :customResource="projetoSelecaoCustomResource"
                :hasExportCSV="false"
                :hasFilter="false"
                :hasNewButton="canGroup"
                :opts="opts"
                @onOpenFormDialog="getProjetosDesagrupados"
              ></master-detail>
            </v-window-item>
          </v-window>
        </v-card>

        <v-snackbar
          v-model="autoSaveNotification"
          color="success"
          text
          outlined
        >
          <v-icon class="custom-loader" color="success" left>mdi-autorenew</v-icon> 
          Salvamento automático.
        </v-snackbar>
        <v-snackbar
          v-model="validationNotification"
          color="error"
          text
          outlined
        >
          <v-icon color="error" left>mdi-alert-circle-outline</v-icon> 
          Existem campos inválidos.
        </v-snackbar>
      </v-col>
      <v-col cols="2" style="min-width: 250px" class="d-flex flex-column">
        <div style="position: sticky; top: 12px">
          <v-card v-if="isAgrupamento" class="teros-elevation mb-6">
            <v-card-actions class="px-2 py-4">              
              <v-btn
                v-if="currentView === 0"
                class="pl-2 pr-2"
                color="primary"
                dark
                depressed
                @click="currentView = 1"
              >
                <v-icon left>mdi-table</v-icon>
                Ver projetos agrupados
              </v-btn>
              <v-btn
                v-if="currentView === 1"
                class="pr-5"
                color="primary"
                dark
                depressed
                @click="currentView = 0"
              >
                <v-icon right>mdi-note-text</v-icon>
                Ver descritivo
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card class="teros-elevation">
            <v-card-subtitle class="pb-1">
              <uploader-v
                v-if="isClient"
                v-model="anexos"
                v-slot="{ selectFiles }"
              >
                <v-btn
                  class="mx-auto d-block px-2"
                  @click="selectFiles()"
                  dense
                  text
                >
                  <v-icon left>mdi-paperclip</v-icon>
                  Anexar arquivos
                </v-btn>
              </uploader-v>
              <span v-else class="v-label-input">Arquivos anexados:</span>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="py-0 pb-2">
              <template v-if="!anexos || anexos.length == 0">
                <v-alert
                  border="left"
                  color="primary"
                  outlined
                  dense
                  text
                  type="info"
                  class="v-label-input"
                >
                  Não existem anexos.
                </v-alert>
              </template>
              <v-card
                elevation="0"
                class="mb-2"
                v-for="(anexo, akey) in anexos"
                :key="akey"
              >
                <v-progress-linear
                  :color="anexo.error ? 'error' : 'secondary'"
                  :value="anexo.percent"
                  :striped="anexo.uploading"
                  height="2em"
                >
                  <v-card-text class="p-2">
                    <v-row
                      justify="space-between"
                      align="center"
                      no-gutters
                      class="py-2 pl-2 pr-0"
                    >
                      <span
                        class="v-label-input text-truncate"
                        :style="{ width: anexo.uploading ? '82%' : '85%' }"
                      >
                        <v-icon left>
                          {{
                            anexo.error
                              ? "mdi-file-document-alert"
                              : "mdi-file-document"
                          }}
                        </v-icon>
                        <span
                          :title="
                            `${anexo.nome}${
                              !anexo.error ? '' : `\n${anexo.error}`
                            }`
                          "
                        >
                          {{ anexo.error || anexo.nome }}
                        </span>
                      </span>
                      <span v-if="anexo.uploading" class="v-label-input">
                        {{ anexo.percent }}%
                      </span>
                      <v-btn
                        v-if="isClient"
                        small
                        icon
                        @click="anexos.splice(akey, 1)"
                      >
                        <v-icon small>mdi-close-circle-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        small
                        icon
                        :href="anexo.url"
                        target="_blank"
                      >
                        <v-icon small>mdi-download</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-progress-linear>
              </v-card>
            </v-card-text>
          </v-card>

          <v-sheet
            class="mt-6 teros-elevation"
            rounded
            v-if="Object.keys(comentarios).length > 0 || !isClient"
          >
            <div class="v-label-input p-3">Comentários:</div>
            <v-select
              v-if="!isClient"
              dense
              hide-details
              outlined
              v-model="selectedComment"
              :items="commentsToAdd"
              item-value="key"
              item-text="value"
              @input="addFieldToComment($event)"
            ></v-select>
            <v-expansion-panels accordion>
              <v-expansion-panel
                v-for="(item, field) in comentarios"
                :key="field"
              >
                <v-expansion-panel-header
                  disable-icon-rotate
                  class="v-label-input px-4"
                >
                  {{ shortName(fields[field].name) }}
                  <template v-slot:actions>
                    <v-icon color="success">mdi-comment-outline</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="ex-panel-no-padding">
                  <v-textarea
                    @input="autoSave($event)"
                    v-model="comentarios[field]"
                    filled
                    dense
                    hide-details
                    :readonly="isClient"
                    :append-icon="isClient ? null : 'mdi-delete-outline'"
                    @click:append="removeFieldFromComment(field)"
                  ></v-textarea>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-sheet>
          <v-card class="teros-elevation mt-6">
            <v-card-actions class="px-2 py-4 justify-center">              
              <v-btn
                v-if="currentStep != 0"
                depressed
                @click="currentStep--"
                color="primary"
                dark
                class="pr-4"
              >
                <v-icon>mdi-chevron-left</v-icon>Voltar
              </v-btn>
              <v-btn
                v-if="currentStep != 3"
                depressed
                @click="currentStep++"
                color="primary"
                dark
                class="pl-4"
              >
                Avançar
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>              
            </v-card-actions>
            <!-- ÚLTIMA ABA -->
            <v-card-actions v-if="currentStep == 3" class="px-5 py-4">
              <v-spacer></v-spacer>
              <!-- ELABORAÇÂO -->
              <template>
                <!-- CLIENTE -->
                <v-btn
                  v-if="isClient"
                  depressed
                  @click="salvaEnquadramento()"
                  color="primary"
                  class="px-5"
                >
                  Finalizado
                </v-btn>
                <template v-else>
                  <!-- CONSULTOR -->
                  <template v-if="isRevisorProjeto">
                    <v-btn
                      v-if="todaRevisaoAprovada"
                      depressed
                      @click="aprovaRevisao()"
                      color="primary"
                      class="px-5"
                    >
                      Aprovar Revisão
                    </v-btn>
                    <v-btn
                      v-else
                      depressed
                      @click="reprovaRevisao()"
                      color="error"
                      class="px-5"
                    >
                      Reprovar Revisão
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      class="px-5"
                      color="primary"
                      depressed
                      :disabled="!canEdit"
                      @click="concluiEnquadramento()"
                    >
                      Concluir Elaboração
                    </v-btn>
                  </template>
                </template>
              </template>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { BeneficiosEnum, BeneficiosLabels } from "@/core/enums/beneficios";
import { StatusProjetoEnum } from "@/core/enums/projetos";
import { AnyAdmin, UserTypeEnum } from "@/core/enums/user-types";
import { projectsOptions } from "@/helpers/yearsOptions";
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  components: {
    "kore-select": () => import("@/components/input-v.vue"),
    "form-modal": () => import("@/components/form-modal.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
    "uploader-v": () => import("@/components/uploader-v.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "jwtData", "user"]),
    canEdit: function () {
      const { status } = this.projeto;
      const { tipo_usuario } = this.user;
      // Se 'Em Revisão Galapos' ou 'Em validação final Galapos' somente Galapos pode editar
      // Aprovado para Submissão ou Submetido - ninguém pode editar, mas consultor ainda pode alterar o status
      return ![StatusProjetoEnum.APROVADO_SUBMISSAO, StatusProjetoEnum.SUBMETIDO].includes(status) &&
        (![StatusProjetoEnum.REVISAO_GALAPOS, StatusProjetoEnum.VALIDACAO_FINAL_GALAPOS].includes(status) || AnyAdmin.includes(tipo_usuario));
    },
    canEditStatus: function () {
      const { status } = this.projeto;
      const { tipo_usuario } = this.user;

      // Aprovado para Submissão ou Submetido - ninguém pode editar, mas consultor ainda pode alterar o status
      return this.canEdit || ([StatusProjetoEnum.APROVADO_SUBMISSAO, StatusProjetoEnum.SUBMETIDO].includes(status) && AnyAdmin.includes(tipo_usuario));
    },
    canGroup: function () {
      return this.userHasAccess('Projetos.agrupamentos.create');
    },
    contextOptions: function () {
      return [
        {
          name: "Ver ficha de análise",
          limit: -1,
          icon: "",
          show: this.userHasAccess('Projetos.analise.create'),
          cb: ({ id }) => {
            const name = [UserTypeEnum.GERENCIAL_CLIENTE, UserTypeEnum.COLABORADOR].includes(this.user.tipo_usuario) ? 'edicao-inclusao-projeto' : 'ficha-projeto-analise';
            const { href } = this.$router.resolve({ name, params: { projetoId: id }});
            window.open(href, '_blank');
          },
        },
        {
          name: "Remover",
          limit: -1,
          icon: "",
          show: this.userHasAccess('Projetos.agrupamentos.create'),
          cb: ({ id, titulo }) => {
            this.showConfirm('Confirmar remoção', `Deseja remover ${titulo} do agrupamento?`, () => this.projetoSelecaoCustomResource.delete(id));
          },
        },
      ]
        .filter(({ show }) => !!show);
    },
    cols: function () {
      return [
        {
          key: "id_projeto",
          name: "Código",
          hideInform: true,
        },
        {
          key: "titulo",
          name: `Nome do ${this.name}`,
          hideInform: true,
        },
        {
          key: "trabalho",
          name: "Benefício",
          rel: { to: "trabalho", key: "id", name: "nome" },
          hideInform: true,
        },
        {
          key: "ano_base",
          name: "Ano Base",
          hideInform: true,
        },
        {
          key: "data_inicio",
          name: "Data Inicial",
          type: this.$fieldTypes.DATE,
          hideInform: true,
        },
        {
          key: "data_fim",
          name: "Data Final",
          type: this.$fieldTypes.DATE,
          hideInform: true,
        },
        {
          key: "projetosSelecionados",
          name: "Projetos sem grupo",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "projetosDesagrupados", key: "id", name: "titulo" },
          hideInTable: true,
        },
      ];
    },
    plurianualField: function () {
      return [
        {
          key: "ano_base",
          name: "Gerar para o ano:",
          type: this.$fieldTypes.SELECT,
          rules: [{ rule: 'required' }],
          rel: { toEdit: projectsOptions, id: "value", name: "text" },
          show: true,
          colSize: this.isAgrupamento ? 4 : 12,
        },
        {
          key: "projetos_agrupados",
          name: "Projetos desse agrupamento que serão replicadas junto do agrupamento",
          placeholder: "Nenhum projeto selecionado, agrupamento sera replicado vazio",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: this.projetosAgrupados, key: "id", name: "titulo" },
          show: this.isAgrupamento,
          colSize: 12,
        },
      ].filter(({ show }) => show);
    },
    plurianualDialogWidth: function () {
      return this.isAgrupamento ? '60%' : '20%';
    },
    fields: function () {
      return {
        cliente: {
          key: "empresaId",
          name: "Cliente",
          type: this.$fieldTypes.AUTOCOMPLETE,
          labelBold: true,
          rel: { to: "clientes", key: "id", name: "fantasia" },
          nao_comenta: true,
        },
        id_projeto: {
          key: "id_projeto",
          name: `Código do ${this.name}`,
          type: this.$fieldTypes.TEXT,
          labelBold: true,
          rules: [{ rule: "required" }],
          nao_comenta: true,
        },
        titulo: {
          key: "titulo",
          name: `Nome do ${this.name}`,
          type: this.$fieldTypes.TEXT,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "min", params: { size: 3 } }, { rule: "max", params: { size: 250 } }],
          counter: 250,
          maxLength: 250,
          nao_comenta: true,
        },
        data_inicio: {
          key: "data_inicio",
          name: "Data de Início",
          type: this.$fieldTypes.DATE,
          labelBold: true,
          rules: [{ rule: "required" }],
          nao_comenta: true,
        },
        data_fim: {
          key: "data_fim",
          name: "Data/Previsão de Término",
          type: this.$fieldTypes.DATE,
          labelBold: true,
          rules: [{ rule: "required" }],
          nao_comenta: true,
        },
        responsavelTxt: {
          key: "responsavelTxt",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }, { rule: "min", params: { size: 3 }}],
          nao_comenta: true,
        },
        responsavelId_cpf: {
          key: "responsavelId_cpf",
          name: "CPF",
          type: this.$fieldTypes.AUTOCOMPLETE,
          nowrap: true,
          rel: { to: "colaboradores", key: "id", name: "cpf" },
          nao_comenta: true,
        },
        responsavelId_email: {
          key: "responsavelId_email",
          name: "E-mail",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "colaboradores", key: "id", name: "email" },
          nao_comenta: true,
        },
        responsavelId_telefone: {
          key: "responsavelId_telefone",
          name: "Telefone",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "colaboradores", key: "id", name: "telefone" },
          nao_comenta: true,
        },
        trabalho: {
          key: "trabalho",
          name: "Benefício",
          type: this.$fieldTypes.SELECT,
          rules: [{ rule: "required" }],
          rel: { to: "trabalho", key: "id", name: "nome" },
        },
        status: {
          key: "status",
          name: "Status",
          type: this.$fieldTypes.SELECT,
          rel: { to: "status", key: "id", name: "label" },
          colSize: 6,
        },
        /* TIPIFICAÇÃO */
        tipo_projetoId: {
          key: "tipo_projetoId",
          name: "Tipo (Escolha uma das opções)",
          type: this.$fieldTypes.RADIO,
          outline: true,
          labelBold: true,
          rules: [{ rule: "required" }],
          rel: { to: "tipoProjeto", key: "id", name: "descricao" },
          validaPreenchido: () => this.isLeiDeInformatica,
        },
        troca_ppb: {
          key: "troca_ppb",
          name: `${this.name} para cumprir troca de PPB por P,D&I? (Escolha uma das opções)`,
          type: this.$fieldTypes.RADIO,
          outline: true,
          labelBold: true,
          rules: [{ rule: "required" }],
          rel: {
            key: "id",
            name: "descricao",
            to: [
              { id: "Sim", descricao: "Sim" },
              { id: "Nao", descricao: "Não" },
            ],
          },
          validaPreenchido: () => this.isLeiDeInformatica,
        },
        alcance: {
          key: "alcance",
          name: "Alcance (pode ser marcado mais de uma opção)",
          type: this.$fieldTypes.CHECKBOXES,
          outline: true,
          labelBold: true,
          rules: [{ rule: "required" }],
          rel: { to: "alcance", key: "id", name: "descricao" },
          validaPreenchido: () => this.isLeiDeInformatica,
        },
        inovacao: {
          key: "inovacao",
          name: "Grau de Inovação (pode ser marcado mais de uma opção)",
          type: this.$fieldTypes.CHECKBOXES,
          outline: true,
          labelBold: true,
          itemsPerLine: 3,
          rules: [{ rule: "required" }],
          rel: { to: "inovacao", key: "id", name: "descricao" },
        },
        area_aplicacaoId: {
          key: "area_aplicacaoId",
          name: "Área de Aplicação (escolha uma das opções)",
          type: this.$fieldTypes.RADIO,
          outline: true,
          labelBold: true,
          itemsPerLine: 1,
          rules: [{ rule: "required" }],
          rel: { to: "areaAplicacao", key: "id", name: "descricao" },
          validaPreenchido: () => this.isLeiDeInformatica,
        },
        qtde_publicacoes: {
          key: "qtde_publicacoes",
          name: "Quantidade de Publicações",
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: "required" }],
          labelBold: true,
          validaPreenchido: () => this.isLeiDeInformatica,
        },
        decreto: {
          key: "decreto",
          name: "Enquadramento no Artigo 24 do Decreto 5906/2006 (pode ser marcado mais de uma opção)",
          type: this.$fieldTypes.CHECKBOXES,
          outline: true,
          labelBold: true,
          itemsPerLine: 1,
          rules: [{ rule: "required" }],
          rel: { to: "decreto", key: "id", name: "descricao" },
          validaPreenchido: () => this.isLeiDeInformatica,
        },
        /* DETALHAMENTO */
        descricao: {
          key: "descricao",
          name: `Descrição do ${this.name}`,
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
          counter: 4000,
          maxLength: 4000,
        },
        tipo_pesquisaId: {
          key: "tipo_pesquisaId",
          name: "PB, PA ou DE (Escolha uma das opções)",
          type: this.$fieldTypes.RADIO,
          outline: true,
          labelBold: true,
          rules: [{ rule: "required" }],
          rel: { to: "tipoPesquisa", key: "id", name: "descricao" },
          validaPreenchido: () => this.isLeiDoBem,
        },
        area_projeto: {
          key: "area_projeto",
          name: `Área do ${this.name}`,
          placeholder: "Digite a área ou selecione uma das opções pré-cadastradas",
          type: this.$fieldTypes.COMBOBOX,
          labelBold: true,
          rules: [{ rule: "required" }],
          rel: { to: "areaProjeto" },
          validaPreenchido: () => this.isLeiDoBem,
        },
        keywords: {
          key: "keywords",
          name: "Palavras-chave",
          ajuda:
            `Informe palavras chaves relacionadas ao ${this.name} de P,D&I separadas por ponto e vírgula (;)`,
          type: this.$fieldTypes.TEXT_CHIP,
          labelBold: true,
          placeholder: "Separe termos por ; ou Enter",
          rules: [
            { rule: "required" },
            (value) => {
              if (!value) {
                return true;
              }

              const array = Array.isArray(value) ? value : [value];
              const txt = array.join('; ');
              return txt.length < 3 ? 'Mínimo de 3 caracteres.' : txt.length > 250 ? 'Máximo de 250 caracteres.' : true;
            },
          ],
          counter: 250,
          maxLength: 250,
          validaPreenchido: () => this.isLeiDoBem,
        },
        naturezaId: {
          key: "naturezaId",
          name: "Natureza (Escolha uma das opções)",
          type: this.$fieldTypes.RADIO,
          outline: true,
          labelBold: true,
          rules: [{ rule: "required" }],
          rel: { to: "natureza", key: "id", name: "descricao" },
          validaPreenchido: () => this.isLeiDoBem,
        },
        elemento_tecnologico: {
          key: "elemento_tecnologico",
          name: "Elemento tecnologicamente novo ou inovador",
          ajuda:
            "O elemento tecnologicamente novo deve representar um progresso científico ou tecnológico. Por progresso científico ou tecnológico compreende-se a aquisição de novos conhecimentos, visando desenvolver/aprimorar produtos, processos e sistemas, o que é a pesquisa aplicada; assim como a comprovação/demonstração de viabilidade técnica ou funcional para produtos, processos, sistemas e serviços ou evidente aperfeiçoamento daqueles já produzidos ou estabelecidos, o que é o desenvolvimento experimental.",
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
          counter: 4000,
          maxLength: 4000,
        },
        desafio_tecnologico: {
          key: "desafio_tecnologico",
          name: "Barreiras ou desafios tecnológicos superáveis",
          ajuda:
            "Consiste em um determinado problema, dificuldade, limitação ou restrição de ordem técnica imposto ao desenvolvimento, compreensão e implementação das novas tecnologias, ou novos conhecimentos. As atividades realizadas para superar devem ser de P&D, que sempre apresentará um resultado, mesmo que seja um indicativo de que a premissa adotada e testada para superação da barreira não deve ser mais seguida.",
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
          counter: 4000,
          maxLength: 4000,
        },
        metodologia: {
          key: "metodologia",
          name: "Metodologia/Contextualização das Etapas",
          ajuda:
            `Neste campo é necessário dissertar especificamente sobre cada atividade de pesquisa e desenvolvimento realizada no decorrer do ano-base, de maneira que fique evidenciada cada uma das etapas do processo de desenvolvimento do ${this.name}, relacionadas com o objetivo e a solução da barreira do ${this.name}. Descrever o que foi feito no ${this.name}, dividindo em macro etapas e suas atividades. Destacar o ano da execução. Ao fim de cada etapa informar o resultado da atividade, os participantes e o período de execução. Destacar qual parte da atividade foi executada por terceiros ou por ICT, se houver. * Novos conhecimentos adquiridos em razão do ${this.name}, * Estudo dos conceitos fundamentais técnico-científicos (mecânica, mecatrônica, outros) do equipamento, * Desenvolvimento do protótipo (matriz tecnológica e estrutural), * Estudo de normas, leis e parâmetros técnicos (segurança, meio ambiente etc.), * Testes e acompanhamento do modelo funcional. Obtenção de dados e elementos de avaliação (Comentar detalhadamente como e onde foram realizados os testes, os parâmetros utilizados, bem como a respeito das conclusões e resultados obtidos), * Se aplicável, informar se houve a fabricação de um ou mais lotes-piloto, e como se deu a sua fabricação, * Comentar sobre eventuais alterações do ${this.name} base, * Novos testes de validação (se aplicável): descrever os testes em detalhes, incluindo parâmetros utilizados, resultados satisfatórios e não satisfatórios, * Homologação final do ${this.name} (caso o ${this.name} ainda não tenha sido finalizado, é preciso comentar sobre as etapas que deverão ser realizadas no ano imediatamente subsequente), *Resultados obtidos no desenvolvimento das etapas *Detalhar pessoas envolvidas em cada atividade e atividades executadas por elas e por terceiros contratados.`,
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
          counter: 4000,
          maxLength: 4000,
        },
        atividade_continua: {
          key: "atividade_continua",
          name: "Atividade é continua (ciclo de vida maior que 1 ano?) (Escolha uma das opções)",
          type: this.$fieldTypes.RADIO,
          outline: true,
          labelBold: true,
          rules: [{ rule: "required" }],
          rel: {
            key: "id",
            name: "descricao",
            to: [
              { id: "Sim", descricao: "Sim" },
              { id: "Nao", descricao: "Não" },
            ],
          },
          validaPreenchido: () => this.isLeiDoBem,
        },
        atividadeAnoBase: {
          key: "atividadeAnoBase",
          name:
            "Atividade de PD&I desenvolvida no ano-base",
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
          validaPreenchido: () => this.isLeiDoBem,
          counter: 4000,
          maxLength: 4000,
        },
        info_complementar: {
          key: "info_complementar",
          name: "Informação complementar",
          ajuda:
            "Informações complementares aos campos anteriores, bem como indicação facultativa de que a empresa enviará em anexo ao formulário com mais detalhamento das atividades de P&D realizadas ou que enviará via postal.",
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
          counter: 4000,
          maxLength: 4000,
          validaPreenchido: () => this.isLeiDoBem,
        },
        /* RESULTADO */
        resultado_economico: {
          key: "resultado_economico",
          name: "Resultado Econômico",
          ajuda:
            "Informe os ganhos econômicos esperados pela empresa com a realização do projeto. São exemplos de informações que devem constar neste tópico: redução de custos em R$ XX; aumento de faturamento em R$ XX; aumento da lucratividade em R$ XX. ",
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 250 } }],
          counter: 250,
          maxLength: 250,
        },
        resultado_inovacao: {
          key: "resultado_inovacao",
          name: "Resultado de Inovação",
          ajuda:
            "Informe os ganhos da empresa na área de inovação. São exemplos de informações que devem constar neste tópico: ganhos de performance, produtividade, redução de custos, aumento de market-share etc.",
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 250 } }],
          counter: 250,
          maxLength: 250,
        },
        recursos_humanos: {
          key: "recursos_humanos",
          name: "Recursos Humanos",
          ajuda:
            "Descrever a vinculação entre os colaboradores que participaram do projeto e as atividades realizadas, apresentando as competências que foram exigidas para execução do projeto. As competências referem-se as qualificações e tipos de profissionais envolvidos no projeto. Se aplicável, mencionar a participação de equipes  terceirizadas,  mencionando as atividades de P&D executadas.",
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
          counter: 4000,
          maxLength: 4000,
        },
        vinculacao_materiais: {
          key: "vinculacao_materiais",
          name: "Vinculação entre os Materiais de Consumo e a Linha de Pesquisa",
          ajuda:
            // "Descrever a vinculação entre os materiais de consumo e a linha de pesquisa, apresentando as <strong>relações entre os materiais utilizados para execução do projeto e a linha de pesquisa</strong>. A relação material-linha de pesquisa deve esclarecer qual a finalidade do uso de determinado material de consumo para o desenvolvimento do projeto. Ex: Materiais utilizados no desenvolvimento de protótipos, materiais de consumo utilizados em testes etc.",
            "Descrever a vinculação entre os materiais de consumo e a linha de pesquisa, apresentando as relações entre os materiais utilizados para execução do projeto e a linha de pesquisa. A relação material-linha de pesquisa deve esclarecer qual a finalidade do uso de determinado material de consumo para o desenvolvimento do projeto. Ex: Materiais utilizados no desenvolvimento de protótipos, materiais de consumo utilizados em testes etc.",
          type: this.$fieldTypes.TEXTAREA,
          labelBold: true,
          rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
          counter: 4000,
          maxLength: 4000,
        },
      };
    },
    isAgrupamento: function () {
      return this.projeto?.agrupamento;
    },
    isGalaposUser: function () {
      return [UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CONSULTOR].includes(
        this.user.tipo_usuario
      );
    },
    isLeiDoBem: function () {
      return [
        BeneficiosEnum.LEI_DO_BEM,
        BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
      ].includes(this.projeto?.trabalho);
    },
    isLeiDeInformatica: function () {
      return [
        BeneficiosEnum.LEI_DE_INFORMATICA,
        BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
      ].includes(this.projeto?.trabalho);
    },
    isRevisorProjeto: function () {
      let revisorProjetosId =
        this.opts.clientes && this.opts.clientes.length > 0
          ? this.opts.clientes.filter((c) => c.id == this.projeto.empresaId)[0]
              .revisorProjetosId
          : null;

      return this.jwtData?.data?.link?.consultorId == revisorProjetosId;
    },
    name: function () {
      return this.projeto?.projetos_agrupados?.length > 0 ? 'Agrupamento' : 'Projeto';
    },
    projetoSelecaoCustomResource: function () {      
      const resource = this.apiResource(`/v1/projetos/${this.clientId}/agrupamento`);      
      const { projetos_agrupados } = this.projeto;
      const updateAgrupamento = async ({ add = [], exclude = [] }) => {
        const { ano_base, id, projetos_agrupados, titulo } = this.projeto;
        const projetos = projetos_agrupados.map(({ id }) => id).concat(add).filter((id) => !exclude.includes(id));
        const body = { ano_base, id, projetos, titulo };
        let response = await resource.save(body, id);

        if (response.error) {
          throw response;
        }

        response = await this.resourceProjetos.get(id);

        if (response.error) {
          throw response;
        }

        this.projeto.projetos_agrupados = response.projeto.projetos_agrupados;
        return response.projeto.projetos_agrupados;
      };

      return {
        async get() {
          return projetos_agrupados;
        },
        save({ projetosSelecionados }) {
          return updateAgrupamento({ add: projetosSelecionados });
        },
        delete(id) {
          return updateAgrupamento({ exclude: [id] });
        },
      };
    },
    todaRevisaoAprovada: function () {
      let revisorFields = _.filter(this.fields, (field, key) =>
        key.startsWith("ck_")
      );
      return !_.some(revisorFields, (f) => this.projeto[f.key] == 0);
    },
    isClient: function() {
      return this.getClient().isClient;
    },
    resourceProjetos: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/projeto`);
    },
    resourceProjetosSelecao: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/selecao?ano=${this.projeto.ano_base}`);
    },
    resourceColaboradores: function () {
      return this.apiResource(`/v1/rh/${this.projeto.empresaId}/selecao`);
    },
    resourceStatus: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/status`);
    },
    commentsToAdd: function () {
      return _.differenceWith(
        Object.keys(this.fields).filter((k) => !this.fields[k].nao_comenta),
        Object.keys(this.comentarios),
        (k1, k2) => {
          return k1 == k2;
        }
      ).map((k) => ({ key: k, value: this.shortName(this.fields[k].name) }));
    },
    cliente: function () {
      return (
        _.find(this.opts.clientes, (c) => c.id == this.projeto.empresaId) || {
          trabalho: 0,
        }
      );
    },
  },
  created: function () {
    this.isLoaded = false;
    this.resourceProjetos.get(this.$route.params.projetoId).then((response) => {
      this.projeto = response.projeto;
      this.projeto.keywords = response.projeto.keywords?.split(';').map((word) => word.trim()) || [];

      this.inovacao = JSON.parse(
        !this.projeto.inovacao || this.projeto.inovacao == ""
          ? "[]"
          : this.projeto.inovacao
      );
      this.decreto = JSON.parse(
        !this.projeto.decreto || this.projeto.decreto == ""
          ? "[]"
          : this.projeto.decreto
      );
      this.alcance = JSON.parse(
        !this.projeto.alcance || this.projeto.alcance == ""
          ? "[]"
          : this.projeto.alcance
      );
      this.preenchimentoConsultor = JSON.parse(
        !this.projeto.preenchimento_consultor ||
          this.projeto.preenchimento_consultor == ""
          ? "{}"
          : this.projeto.preenchimento_consultor
      );
      this.comentarios = JSON.parse(
        !this.projeto.comentario || this.projeto.comentario == ""
          ? "{}"
          : this.projeto.comentario
      );
      this.anexos = JSON.parse(
        !this.projeto.anexo || this.projeto.anexo == ""
          ? "[]"
          : this.projeto.anexo
      );

      /* ENQUANTO NÂO NO PROJETO  */
      if (typeof this.projeto.trabalho == "undefined") {
        this.projeto.trabalho = response.clientes.trabalho;
      }

      this.opts.clientes = [response.clientes];
      this.opts.tipoProjeto = response.tipoProjeto;
      this.opts.alcance = response.alcance;
      this.opts.inovacao = response.inovacao;
      this.opts.areaAplicacao = response.areaAplicacao;
      this.opts.decreto = response.decreto;
      this.opts.tipoPesquisa = response.tipoPesquisa;
      this.opts.areaProjeto = response.areaProjeto.map((s) => s.descricao).sort();
      this.opts.natureza = response.natureza;
      this.projetosAgrupados = this.formatProjetosOptions(response.projeto.projetos_agrupados);
    });

    this.resourceStatus.get().then((response) => {
      this.opts.status = response.sort((a, b) => a.ordem - b.ordem).reduce((acc, curr) => {
        if (!curr.label) {
          return acc;
        }

        if ([
            StatusProjetoEnum.PENDENTE_ANALISE,
            StatusProjetoEnum.ATRASO,
            StatusProjetoEnum.INFORMACOES_PENDENTES,
            StatusProjetoEnum.NAO_ENQUADRADO,
          ].includes(curr.id)) {
          curr.disabled = true;
        }

        return [...acc, curr];
      }, []);
    });

    this.autoSaveDebounce = _.debounce(
      () => {
        this.salvaEnquadramento(true);
      },
      3000,
      { maxWait: 10000 }
    );
    this.onKeypressDebounce = _.debounce(this.onKeypress, 5100, { leading: true, trailing: false });
    this.onKeypressKeywordsDebounce = _.debounce(this.onKeypressKeywords, 5100, { leading: true, trailing: false });
    this.onPasteDebounce = _.debounce(this.onPaste, 5100, { leading: true, trailing: false });
    this.onPasteKeywordsDebounce = _.debounce(this.onPasteKeywords, 500, { leading: true, maxWait: 1000, trailing: false });
  },
  watch: {
    anexos: function () {
      this.autoSave("anexos");
      this.isLoaded = true;
    },
  },
  methods: {
    formatProjetosOptions: function (projetos) {
      if (!Array.isArray(projetos)) {
        return [];
      }

      return projetos
        .map((projeto) => {
            const trabalho = BeneficiosLabels[projeto.trabalho] || 'Sem Benefício';
            const titulo = `${projeto.titulo || 'Título não encontrado'} [${trabalho}]`;
            const disabled = this.projeto.trabalho != projeto.trabalho;
            return { ...projeto, disabled, titulo };
          })
          .sort((a, b) => {
            const { titulo: tituloA, trabalho: trabalhoA } = a;
            const { titulo: tituloB, trabalho: trabalhoB } = b;
            const disabledA = a.disabled ? 1 : 0;
            const disabledB = b.disabled ? 1 : 0;
            return disabledA - disabledB || trabalhoA - trabalhoB || tituloA.localeCompare(tituloB);
          });
    },
    getProjetosDesagrupados: async function() {
      const { projetos } = await this.resourceProjetosSelecao.get();
      const projetosDesagrupados = projetos?.filter(
        ({ agrupamento_id }) => !agrupamento_id
      );
      this.opts.projetosDesagrupados = this.formatProjetosOptions(
        projetosDesagrupados
      );
    },
    isValidForm: function () {
      let invalid = Object.entries(this.fields)
        .map((s) => s[1])
        .filter((i) => !i.hideInform && i.editable !== false && i.valid)
        .map((l) => {
          return l.valid() ? "VALIDO" : "INVÁLIDO";
        })
        .find((valid) => valid == "INVÁLIDO");
      return !invalid;
    },
    removeFieldFromComment: function (key) {
      Vue.delete(this.comentarios, key);
      this.autoSave();
      this.selectedComment = {};
    },
    addFieldToComment: function (key) {
      Vue.set(this.comentarios, key, "");
      this.selectedComment = {};
    },
    shortName: function (nameField) {
      let idxParentesis = nameField.indexOf(" (");
      return nameField.substring(
        0,
        idxParentesis > 0 ? idxParentesis : nameField.length
      );
    },
    autoSave: function () {
      if (this.isLoaded) {
        this.autoSaveDebounce();
      }
    },
    isRequired: (field) =>
      field.rules && _.some(field.rules, (r) => r.rule == "required"),
    fieldsByLei: (field) => !field.validaPreenchido || field.validaPreenchido(),
    isVazio: (val) =>
      (!val || val === "" || (Array.isArray(val) && val.length === 0)) && val !== 0 && val !== "0",
    save: function (silent = false) {
      this.projeto.empresaId = this.clientId;
      this.projeto.comentario = JSON.stringify(this.comentarios);
      this.projeto.anexo = JSON.stringify(this.anexos);
      this.projeto.inovacao = this.inovacao;
      this.projeto.decreto = this.decreto;
      this.projeto.alcance = this.alcance;

      if (
        this.cliente.trabalho != 3 &&
        this.projeto.trabalho != this.cliente.trabalho
      ) {
        this.projeto.trabalho = this.cliente.trabalho;
      }

      const projeto = _.cloneDeep(this.projeto);
      projeto.keywords = !projeto.keywords.length ? '' : projeto.keywords.join('; ');

      if (silent) {
        this.autoSaveNotification = true;
        this.resourceProjetos
          .silentSave(projeto, this.projeto.id)
          .then((response) => {
            this.projeto.id = response.id;
          });
      } else {
        this.resourceProjetos
          .save(projeto, this.projeto.id)
          .then((response) => {
            this.projeto.id = response.id;
            this.$router.go(0);
          });
      }
    },
    hasComentarios: function () {
      return (
        Object.keys(this.comentarios).length > 0 &&
        _.some(this.comentarios, (c) => c && c != "")
      );
    },
    salvaEnquadramento: function (silent = false) {
      this.save(silent);
    },
    clienteAprovaFichaResumo: function() {
      this.save();
    },
    revisorAprovaFichaResumo: function() {
      this.save();
    },
    revisorReprovaFichaResumo: function() {
      this.save();
    },
    alterarStatusRevisão: function(aprovar = false) {
      this.apiResource(
        `v1/projetos/${this.clientId}/enquadrados/revisao-${
          aprovar ? "aprovada" : "reprovada"
        }/${this.projeto.id}`
      )
        .save(this.projeto)
        .then(() => this.$router.go(0));
    },
    aprovaRevisao: function () {
      this.resourceProjetos
        .patch(this.projeto, `${this.projeto.id}/enquadrados/revisao-aprovada`)
        .then(() => {
          const route = this.clientRoute(`/projetos/enquadrados`);
          this.$router.push(route);
        });
    },
    reprovaRevisao: function () {
      this.resourceProjetos
        .patch(this.projeto, `${this.projeto.id}/enquadrados/revisao-reprovada`)
        .then(() => {
          const route = this.clientRoute(`/projetos/enquadrados`);
          this.$router.push(route);
        });
    },
    concluiEnquadramento: function () {
      this.resourceProjetos
        .patch(this.projeto, `${this.projeto.id}/enquadrados/elaboracao`)
        .then(() => {
          const route = this.clientRoute(`/projetos/enquadrados`);
          this.$router.push(route);
        });
    },
    gerarProximoAnoBase: function ({ ano_base, projetos_agrupados }) {      
      const dataFimProjeto = this.projeto.data_fim;
      const dataAnoBase = moment(dataFimProjeto).get('year')      
      if(dataFimProjeto && (dataAnoBase < ano_base))
      {
        const dataFimExibicao = moment(dataFimProjeto).format('DD/MM/YYYY')
        this.showConfirm(
        `Projeto encerrado em ${dataFimExibicao}`, 
        `Deseja prosseguir com a replicação do projeto para o ano ${ano_base}? O novo projeto será criado com data fim indefinida.`, 
        () => {          
          this.gerarAnoBase(ano_base, projetos_agrupados);
        });
      } else {
        this.gerarAnoBase(ano_base, projetos_agrupados);
      }
    },
    gerarAnoBase: function(ano_base, projetos_agrupados)  {
      this.apiResource(`v1/projetos/${this.clientId}/plurianual/${this.projeto.id}`)
        .save({ ano_base, projetos_agrupados })
        .then((response) => {
          if (response.error) {
            return;
          }

          this.plurianualDialog = false;
          const route = this.$router.resolve({ name: "ficha-projeto-enquadrado", params: { projetoId: response.id }});
          window.open(route.href, "_blank");
      });
    },
    onPlurianualOpen: function () {
      this.plurianualData = {
        projetos_agrupados: this.isAgrupamento ? this.projeto.projetos_agrupados.map(({ id }) => id) : undefined,
      };
    },
    onKeypress: function (event) {
      const checkLength = () => {
        const { maxLength, value } = event.target;

        if (maxLength > -1 && value.length >= maxLength) {
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "alert",
            title: "Limite de caracteres atingido",
            text: `Você não pode inserir mais de ${maxLength} caracteres neste campo.`,
          });
        }
      };

      // Compensar o delay entre o evento keypress e o change do componente
      setTimeout(checkLength, 100);
    },
    onKeypressKeywords: function (event) {
      const { maxLength, length } = event.target;
      if (maxLength > -1 && length >= maxLength) {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "alert",
          title: "Limite de caracteres atingido",
          text: `Você não pode inserir mais de ${maxLength} caracteres neste campo.`,
        });
      }
    },
    onPaste: function (event) {
      const currText = event.target.value;
      const maxLength = event.target.maxLength;
      const pasteText = event.clipboardData.getData('text');
      const nextLength = currText.length + pasteText.length;

      if (maxLength > -1 && nextLength > maxLength) {
        event.preventDefault();
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "alert",
          title: "Ação colar interrompida",
          text: `A ação iria exceder o limite de ${maxLength} caracteres do campo (iria para ${nextLength} caracteres).`,
        });
      }
    },
    onPasteKeywords: function ({ target, pasteData }) {
      const { maxLength, value } = target;
      const parsedValue = value.join('; '); 
      const nextLength = parsedValue.length + pasteData.length;

      if (maxLength > -1 && nextLength > maxLength && parsedValue !== pasteData) {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "alert",
          title: "Ação colar interrompida",
          text: `A ação iria exceder o limite de ${maxLength} caracteres do campo (iria para ${nextLength} caracteres).`,
        });
      }
    },
  },
  data: function () {
    return {
      autoSaveDebounce: () => {},
      onKeypressDebounce: () => {},
      onKeypressKeywordsDebounce: () => {},
      onPasteDebounce: () => {},
      onPasteKeywordsDebounce: () => {},
      projetosAgrupados: [],
      plurianualDialog: false,
      plurianualData: {},
      status: [],
      selectedComment: null,
      currentStep: 0,
      currentView: 0,
      isLoaded: false,
      autoSaveNotification: false,
      validationNotification: false,
      anexos: [],
      inovacao: [],
      decreto: [],
      alcance: [],
      comentarios: {},
      preenchimentoConsultor: {},
      projeto: {
        id: null,
        trabalho: null,
        id_projeto: null,
        criado_em: null,
        modificado_em: null,
        empresaId: null,
        consultorId: null,
        // responsavelId: null,
        responsavelTxt: null,
        titulo: null,
        resumo: null,
        anexo: null,
        descricao: null,
        enquadramento: null,
        status: null,
        data_analise: null,
        data_inicio: null,
        data_fim: null,
        ano_base: null,
        tipo_projetoId: null,
        troca_ppb: null,
        area_aplicacaoId: null,
        qtde_publicacoes: null,
        tipo_pesquisaId: null,
        area_projeto: null,
        keywords: [],
        naturezaId: null,
        elemento_tecnologico: null,
        desafio_tecnologico: null,
        metodologia: null,
        atividade_continua: null,
        info_complementar: null,
        resultado_economico: null,
        resultado_inovacao: null,
        projetos_agrupados: [],
        recursos_humanos: null,
        vinculacao_materiais: null
      },
      opts: {
        clientes: [],
        tipoProjeto: [],
        alcance: [],
        status: [],
        decreto: [],
        inovacao: [],
        areaAplicacao: [],
        tipoPesquisa: [],
        areaProjeto: [],
        natureza: [],
        projetosDesagrupados: [],
        trabalho: [
          {
            id: 0,
            nome: "Nenhum",
            disabled: true, // essa opção pode vir assim, mas não deve ser selecionavel
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM,
            nome: BeneficiosLabels.LEI_DO_BEM,
          },
          {
            id: BeneficiosEnum.LEI_DE_INFORMATICA,
            nome: BeneficiosLabels.LEI_DE_INFORMATICA,
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
            nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA,
          },
        ],
      },
    };
  }
};
</script>

<style lang="scss" scoped>
.v-progress-linear__content {
  .v-btn > .v-btn__content .v-icon,
  .v-label-input {
    &,
    & .theme--light.v-icon {
      color: rgba(255, 255, 255, 0.87);
    }
  }
}
</style>

<style>
.ex-panel-no-padding .v-expansion-panel-content__wrap {
  padding: 0px;
}

.ex-panel-no-padding .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
}
</style>
